<template>
  <div>
    <div class="login-form">
      <div class="title">{{$t('RetrievePassword')}}</div>
      <div class="form-item">
        <div class="ipt">
          <input type="text" autocomplete="off" :placeholder="$t('Please enter your email address/mobile number')" v-model.trim="userAccount"/>
        </div>
      </div>
      <div class="form-item">
        <div class="ipt">
          <input type="password" autocomplete="off" :placeholder="$t('Please enter your password')" v-model.trim="password"/>
        </div>
      </div>
      <div class="form-item">
        <div class="ipt">
          <input type="password" autocomplete="off" :placeholder="$t('Please enter your password again')" v-model.trim="confirmPassword"/>
        </div>
      </div>
      <div class="form-item">
        <div class="ipt">
          <input class="ipt" type="text" autocomplete="off" :placeholder="$t('Please enter verification code')" v-model.trim="captcha"/>
        </div>
        <button class="btnCaptchaName" :disabled="disableCaptcha" @click="getCaptcha">{{btnCaptchaName}}</button>
      </div>
      <a class="btn-submit" :disabled="disableRegister" @click="onSubmit">{{$t('ResetPassword')}}</a>
    </div>
  </div>
</template>
<script>
  import sendAuthCode from '../../mixins/sendAuthCode';
  import {isEmail, isPhone} from '@/utils/validate';
  import {resetPassword} from '../../api/user';

  export default {
    name: 'ForgetPassword',
    data () {
      return {
        smsType: 'reset',
        userAccount: '',
        password: '',
        confirmPassword: '',
        captcha: ''
      };
    },
    computed: {
      disableCaptcha: function () {
        return this.duration > 0 || !(isEmail(this.userAccount) || isPhone(this.userAccount));
      },
      disableRegister: function () {
        return !(isEmail(this.userAccount) || isPhone(this.userAccount)) || this.password.length < 6 || !this.captcha;
      }
    },
    mixins: [sendAuthCode],
    mounted() {
      document.querySelector('html').classList.add('login-main');
    },
    destroyed() {
      document.querySelector('html').classList.remove('login-main');
    },
    methods: {
      onSubmit () {
        const data = {
          userAccount: this.userAccount,
          password: this.password,
          captcha: this.captcha
        };

        if (data.password !== this.confirmPassword) {
          this.$message.warning(this.$t('Inconsistent password entered twice'));
          return false;
        }

        resetPassword(data).then(res => {
          this.$message.success(this.$t('Reset password successfully'));
          this.$router.go(-1);
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "style";
  .login-form{padding-bottom:30px;}
</style>
